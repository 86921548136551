import { NgModule } from '@angular/core';
import { NgxTwitterTimelineComponent } from './ngx-twitter-timeline.component';

@NgModule({
  declarations: [NgxTwitterTimelineComponent],
  imports: [
  ],
  exports: [NgxTwitterTimelineComponent]
})
export class NgxTwitterTimelineModule { }
